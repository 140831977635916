import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { faCodepen, faGithub, faKeybase, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import './index.scss'

library.add(faCodepen)
library.add(faEnvelope)
library.add(faGithub)
library.add(faKeybase)
library.add(faLinkedin)

dom.watch()
